import { User } from "firebase/auth";
import { createContext } from "react";

interface IAppContext {
  authorizedUser: User | null;
  isLoadingAuth: boolean;
}

export const AppContext = createContext<IAppContext>({
  authorizedUser: null,
  isLoadingAuth: true,
});
