import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { AuthRoutes, MainRoutes } from "./Routes";
import { LoginController } from "../views/login/LoginController";
import { Logout } from "../views/logout/Logout";
import { OauthController } from "../views/oauth/OauthController";
import { ServicesController } from "../views/services/ServicesController";
import { SettingsController } from "../views/settings/SettingsController";

export function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={MainRoutes.ROOT}>
          <Redirect to={MainRoutes.SERVICES} />
        </Route>
        <PublicRoute
          path={MainRoutes.FORGOT_PASSWORD}
          component={LoginController}/>
        <PublicRoute
          path={MainRoutes.LOGIN}
          component={LoginController}/>
        <PrivateRoute
          path={MainRoutes.LOGOUT}
          component={Logout} />
        <PrivateRoute
          path={MainRoutes.SERVICES}
          component={ServicesController} />
        <PrivateRoute
          path={MainRoutes.SETTINGS}
          component={SettingsController} />
        {Object.values(AuthRoutes).map(route =>
          <PrivateRoute
            key={route}
            path={route}
            component={OauthController}/>)}
        <Route>
          <Redirect to={MainRoutes.LOGIN} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
