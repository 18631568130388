import React from "react";
import './ActionButton.scss'

interface IActionButtonProps {
  type?: "submit" | "reset" | "button";
  onClick?: () => void;
  children?: string;
}

export function ActionButton(props: IActionButtonProps) {
  return (
    <button type={props.type} className="action-button" onClick={props.onClick} >
      {props.children}
    </button>
  );
}
