import { FirebaseOptions } from "firebase/app";
import { ThirdPartyServices } from "shared/dist/index";
import { IService } from "./interfaces/IServices";
import { AuthRoutes } from "./router/Routes";

interface IClientIdConfig {
  client_id: string;
}

interface ISpotifyConfig extends IClientIdConfig{
  scope: string[];
}

// NOTE: Under no circumstances should the client_secret be stored here in frontend.
interface IAppConfig {
  firebaseConfig: FirebaseOptions;
  spotifyConfig: ISpotifyConfig;
  husqvarnaConfig: IClientIdConfig;
  mieleConfig: IClientIdConfig;
  homeConnectConfig: IClientIdConfig;
}

export const AppConfig: IAppConfig = {
  firebaseConfig: {
    apiKey: "AIzaSyBGZL_w7NNubtWYXKgphFXKPVKEp-ZRKbs",
    authDomain: "smart-home-services-a16b2.firebaseapp.com",
    projectId: "smart-home-services-a16b2",
    storageBucket: "smart-home-services-a16b2.appspot.com",
    messagingSenderId: "299715552840",
    appId: "1:299715552840:web:ecffe0053470df74171a7d",
    measurementId: "G-DPWTTX09B5",
  },
  spotifyConfig: {
    client_id: "14372e71f04d4f64b6f58afe388bbf32",
    scope: [
      "user-follow-read",
      "user-follow-modify",
      "user-top-read",
      "user-read-playback-position",
      "user-read-recently-played",
      "user-library-modify",
      "user-library-read",
      "playlist-read-collaborative",
      "playlist-modify-public",
      "playlist-read-private",
      "playlist-modify-private",
      "user-read-email",
      "user-read-private",
      "streaming",
      "app-remote-control",
      "user-read-playback-state",
      "user-modify-playback-state",
      "user-read-currently-playing",
      "ugc-image-upload",
    ],
  },
  husqvarnaConfig: {
    client_id: "c13bcc81-c5f6-43fd-ba1c-8ae48f3d0ce1",
  },
  mieleConfig: {
    client_id: "5ec5d630-05df-4212-9d56-92af48411c1b",
  },
  homeConnectConfig: {
    client_id: "00171B863D5F2E1CDA2AA58A37CE9C051296626C9E34BE35CB31DBE4EF5A094D",
  },
};

const spotifyRedirectUrl = encodeURIComponent(`${window.location.origin}${AuthRoutes.AUTH_SPOTIFY}`);
const husqvarnaRedirectUrl = encodeURIComponent(`${window.location.origin}${AuthRoutes.AUTH_HUSQVARNA}`);
const mieleRedirectUrl = encodeURIComponent(`${window.location.origin}${AuthRoutes.AUTH_MIELE}`);
const homeConnectRedirectUrl = encodeURIComponent(`${window.location.origin}${AuthRoutes.AUTH_HOME_CONNECT}`);

export const SPOTIFY_REQUEST_URL = `https://accounts.spotify.com/authorize?client_id=${AppConfig.spotifyConfig.client_id}&response_type=code&redirect_uri=${spotifyRedirectUrl}&scope=${encodeURIComponent(AppConfig.spotifyConfig.scope.join(" "))}`;
export const HUSQVARNA_REQUEST_URL = `https://api.authentication.husqvarnagroup.dev/v1/oauth2/login?client_id=${AppConfig.husqvarnaConfig.client_id}&redirect_uri=${husqvarnaRedirectUrl}`;
export const MIELE_REQUEST_URL = `https://api.mcs3.miele.com/thirdparty/login?client_id=${AppConfig.mieleConfig.client_id}&response_type=code&redirect_uri=${mieleRedirectUrl}`;
export const HOME_CONNECT_REQUEST_URL = `https://api.home-connect.com/security/oauth/authorize?client_id=${AppConfig.homeConnectConfig.client_id}&response_type=code&redirect_uri=${homeConnectRedirectUrl}`;

// TODO: This should come from remote config
export const AVAILABLE_SERVICES: IService[] = [
  { serviceNameId: ThirdPartyServices.SPOTIFY, serviceNiceName: "Spotify", serviceAuthUrl: SPOTIFY_REQUEST_URL },
  { serviceNameId: ThirdPartyServices.HUSQVARNA, serviceNiceName: "Husqvarna", serviceAuthUrl: HUSQVARNA_REQUEST_URL },
  { serviceNameId: ThirdPartyServices.MIELE, "serviceNiceName": "Miele", serviceAuthUrl: MIELE_REQUEST_URL },
  { serviceNameId: ThirdPartyServices.HOMECONNECT, "serviceNiceName": "HomeConnect", serviceAuthUrl: HOME_CONNECT_REQUEST_URL },
];

