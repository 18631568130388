import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MainRoutes } from "../../router/Routes";
import { OauthLoginHandler } from "../../logic/OauthLoginHandler";
import { TopBarController } from "../topBar/TopBarController";
import { LoadingView } from "../loading/LoadingView";
import { Translation } from "../../translations/Translations";
import { LanguageContext } from "../../context/LanguageContext";

export function OauthController() {
  const languageContext = useContext(LanguageContext);

  const history = useHistory();
  const oauthLoginHandler = new OauthLoginHandler();

  useEffect(() => {
    oauthLoginHandler.handleOauthLogin().finally(() => {
      history.push(MainRoutes.SERVICES);
    });
  }, []);

  return <div>
    <TopBarController />
    <LoadingView
      title={languageContext.translate(Translation.ADDING_SERVICE)}
      subTitle={languageContext.translate(Translation.ADDING_SERVICE_LONG)} />
  </div>;

}
