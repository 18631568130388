import React, { useContext, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { LanguageKey } from "../../translations/Translations";

import "./LanguagePicker.scss";

interface ILanguagePickerProps {

}

export function LanguagePicker(props: ILanguagePickerProps) {
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const languageContext = useContext(LanguageContext);

  const inactiveLocales = languageContext.supportedLanguages.filter(
    locale => locale !== languageContext.language,
  );

  return (
    <div className={`language-picker-container${isDropdownOpen ? " is-open" : ""}`}>
      <img
        onClick={() => {
          setDropdownOpen(!isDropdownOpen);
        }}
        className="language-picker-image"
        src={`${process.env.PUBLIC_URL}/assets/localization/${languageContext.language}.svg`}
      />
      {isDropdownOpen &&
        inactiveLocales.map(locale => (
          <img
            key={locale}
            onClick={() => {
              languageContext.setLanguage(locale as LanguageKey);
              setDropdownOpen(false);
            }}
            className="language-picker-image language-picker-list-image"
            src={`${process.env.PUBLIC_URL}/assets/localization/${locale}.svg`}
          />
        ))}
    </div>
  );
}
