import { useContext } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { Routes } from "../router/Routes";
import { LoadingView } from "../views/loading/LoadingView";

export const PublicRoute = (props: RouteProps) => {
  const location = useLocation();
  const appContext = useContext(AppContext);

  if (appContext.isLoadingAuth) {
    return <LoadingView />;
  }

  return !appContext.authorizedUser ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: Routes.SERVICES,
        state: { from: location },
      }}
    />
  );
};
