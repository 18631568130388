export enum MainRoutes{
  ROOT = "/",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  LOGOUT = "/logout",
  SERVICES = "/services",
  SETTINGS = "/settings"
}

export enum AuthRoutes {
  AUTH_SPOTIFY = "/auth-spotify",
  AUTH_HUSQVARNA = "/auth-husqvarna",
  AUTH_MIELE = "/auth-miele",
  AUTH_HOME_CONNECT= "/auth-home-connect"
}

export const Routes = { ...MainRoutes, ...AuthRoutes };

