import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { LanguageKey, Translation } from "../../translations/Translations";

import "./Settings.scss";

interface ISettingsProps {
  apiKey: string | null;
  onGenerateApiKey: () => void;
}

export function Settings(props: ISettingsProps) {
  const languageContext = useContext(LanguageContext);

  return (
    <div className="settings-container">
      <h1>
        {languageContext.translate(Translation.SETTINGS)}
      </h1>

      <h3>
        {languageContext.translate(Translation.API_KEY)}
      </h3>
      <input
        readOnly
        value={props.apiKey || ""} />
      {/* TODO: Add confirmation modal this is dangerous */}
      <button onClick={props.onGenerateApiKey}>
        {languageContext.translate(Translation.GENERATE_NEW)}
      </button>
      <br />

      <h3>
Language
      </h3>
      <select
        name="Select"
        onChange={(event) => {
          languageContext.setLanguage(event.target.value as LanguageKey);
        }}
      >
        {languageContext.supportedLanguages.map(languageKey => (
          <option key={languageKey}>
            {languageKey}
          </option>
        ))}
      </select>
    </div>
  );
}
