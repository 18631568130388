import React from "react";
import { Loader } from "../../components/Loader/Loader";

interface ILoadingViewProps {
  title?: string;
  subTitle?: string;
}

export function LoadingView(props: ILoadingViewProps) {
  return (
    <div className="oauth-controller">
      <h1>
        {props.title}
      </h1>
      <Loader />
      <p>
        {props.subTitle}
      </p>
    </div>
  );
}
