import React from "react";
import "./Loader.scss";

export function Loader() {
  return (
    <div className="loader">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
