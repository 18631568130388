import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { LanguageContext } from "../../context/LanguageContext";
import { clientModules } from "../../modules/ClientModules";
import { MainRoutes } from "../../router/Routes";
import { TopBarController } from "../topBar/TopBarController";
import { Settings } from "./Settings";

interface ISettingsControllerProps {
}

export function SettingsController(props: ISettingsControllerProps) {
  const appContext = useContext(AppContext);
  const [apiKey, setapiKey] = useState<string|null>(null);

  const loadData = async () => {
    const apiKey = await clientModules.getApikey("apiKey");

    setapiKey(apiKey);
  };

  const handleGenerateapiKey = () => {
    clientModules
      .getClientApi()
      .generateAuthToken()
      .finally(() => {
        loadData().finally(() => {

        });
      });
  };

  useEffect(() => {
    if (appContext.authorizedUser) {
      loadData().finally(() => {});
    }
  },[]);

  return (
    <>
      <TopBarController />
      <Settings
        apiKey={apiKey}
        onGenerateApiKey={handleGenerateapiKey}/>
    </>
  );
}
