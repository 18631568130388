import { User } from "firebase/auth";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { Translation } from "../../translations/Translations";

import "./TopBarView.scss";

interface ITopBarViewProps {
  user: User | null;
  onOpenSettings: () => void;
  onLogout: () => void;
}

export function TopBarView(props: ITopBarViewProps) {
  const languageContext = useContext(LanguageContext);

  return (
    <div className="top-bar">
      <div className="top-bar-email">
        {props.user?.email}
      </div>
      <button
        className="top-bar-logout-button submit-btn"
        onClick={props.onOpenSettings}
      >
        {languageContext.translate(Translation.SETTINGS)}
      </button>
      <button
        className="top-bar-logout-button submit-btn"
        onClick={props.onLogout}
      >
        {languageContext.translate(Translation.LOGOUT)}
      </button>
    </div>
  );
}
