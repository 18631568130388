import React from "react";
import { Loader } from "../Loader/Loader";
import "./ServiceCard.scss";
interface IServiceCardProps {
  img: string | JSX.Element;
  title: string;
  isLoading?: boolean;
  onCardClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDeleteClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function ServiceCard(props: IServiceCardProps) {
  return (
    <div
      className={`service-card${props.onCardClick ? " clickable" : ""}`}
      role="button"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (props.onCardClick) {
          props.onCardClick(event);
        }
      }}>
      {props.isLoading ? <div className="service-card-loader">
        <div className="service-card-loader-overlay" />
        <Loader />
      </div> :
        <>
          {props.onDeleteClick && <button
            onClick={props.onDeleteClick}
            className="service-card-close-button">
x
          </button>}
          {typeof props.img === "string" ? (
            <img
              className="service-card-image"
              src={props.img} />
          ) : (
            props.img
          )}
          <div>
            <h3>
              {props.title}
            </h3>
          </div>
        </>
      }
    </div>
  );
}
