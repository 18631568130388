import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import { clientModules } from "../../modules/ClientModules";
import { MainRoutes } from "../../router/Routes";
import { Translation } from "../../translations/Translations";
import { LoadingView } from "../loading/LoadingView";
import { TopBarController } from "../topBar/TopBarController";

export function Logout() {
  const languageContext = useContext(LanguageContext);
  const history = useHistory();

  useEffect(() => {
    clientModules.getAuth()
      .signOut()
      .then(() => {
        history.push(MainRoutes.LOGIN);
      })
      .catch((error) => {
        console.error(error);
        history.push(MainRoutes.LOGIN);
      });
  }, []);

  return <>
    <TopBarController />
    <LoadingView
      title={languageContext.translate(Translation.LOGGING_OUT)} />
  </>;
}
