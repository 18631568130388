export enum Translation {
  SERVICES_VIEW_TITLE = "SERVICES_VIEW_TITLE",
  ADD_SERVICE = "ADD_SERVICE",
  ADD_SERVICE_MODAL_TITLE = "ADD_SERVICE_MODAL_TITLE",
  SETTINGS = "SETTINGS",
  LOGOUT = "LOGOUT",
  LOGGING_OUT = "LOGGING_OUT",
  ADDING_SERVICE = "ADDING_SERVICE",
  ADDING_SERVICE_LONG = "ADDING_SERVICE_LONG",
  USERNAME = "USERNAME",
  PASSWORD = "PASSWORD",
  LOGIN_TITLE = "LOGIN_TITLE",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  API_KEY = "API_KEY",
  GENERATE_NEW = "GENERATE_NEW"
}

export enum LanguageKey {
  EN = "en",
  SK = "sk",
  HU = "hu"
}
