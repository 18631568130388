import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Router } from "./router/Router";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal";
import { LanguageContext } from "./context/LanguageContext";
import { LanguageKey, Translation } from "./translations/Translations";
import { useEffect, useState } from "react";
import translations from "./translations/translations.json";
import { clientModules } from "./modules/ClientModules";
import { AppContext } from "./context/AppContext";
import { User } from "@firebase/auth";

Modal.setAppElement("#root");

function App() {
  const auth = clientModules.getAuth();

  const defaultLanguage = localStorage.getItem("locale");
  const [language, setLocale] = useState<LanguageKey>(defaultLanguage as LanguageKey || LanguageKey.SK);
  const [user, setUser] = useState<User | null>(auth.currentUser);
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(true);

  const translate = (translationKey: Translation) => translations[language][translationKey];

  const setLocaleState = (languageKey: LanguageKey) => {
    localStorage.setItem("locale", languageKey);

    setLocale(languageKey);
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      setIsLoadingAuth(false);
    });
  }, []);

  return (
    <div
      className="App"
      id="app">
      <LanguageContext.Provider value={{ language, setLanguage: setLocaleState, supportedLanguages: ["sk", "hu", "en"], translate }}>
        <AppContext.Provider value={{ authorizedUser: user, isLoadingAuth }}>
          <Router/>
        </AppContext.Provider>
      </LanguageContext.Provider>
      <ToastContainer />
    </div>
  );
}

export default App;
