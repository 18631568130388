import { IEndpointsApi } from "shared/src/models/endpoints/Endpoints";
import { Functions, httpsCallable } from "firebase/functions";
import {
  IGenericStrippedResponse,
  IStandardAuthRequest,
} from "../../../shared/src/models/endpoints";

export class ClientApi implements IEndpointsApi {
  _functions: Functions;

  constructor(functions: Functions) {
    this._functions = functions;
  }

  removeService = async (request: { serviceId: string; } ) => {
    await httpsCallable<string, void>(
      this._functions,
      "removeService",
    )(JSON.stringify(request));
  };

  standardAuth = async (request: IStandardAuthRequest) => {
    const res = await httpsCallable<string, IGenericStrippedResponse>(
      this._functions,
      "standardAuth",
    )(JSON.stringify(request));

    return res.data;
  };

  generateAuthToken = async () => {
    const response = await httpsCallable(
      this._functions,
      "generateAuthToken",
    )();
    if (typeof response.data === "string") {
      return response.data;
    }

    return "";
  };

  getServices = async () => {
    const response = await httpsCallable(
      this._functions,
      "getServices",
    )();
    if (typeof response.data === "string") {
      return JSON.parse(response.data);
    }

    return [];
  };
}
