import { getCodeFromUrl } from "../utils/utils";
import { clientModules } from "../modules/ClientModules";
import { AuthRoutes } from "../router/Routes";
import { AVAILABLE_SERVICES } from "../Appconfig";
import { ThirdPartyServices } from "shared/dist/index";

/**
 * This class is used to handle all oauth callbacks, we are basically filtering out by
 * window.location.pathname, which service is used, then calling the appropriate handler function.
 */
export class OauthLoginHandler {
  // TODO: This should be refactored all configs should have a structure like { spotify: {requestUrl: "", } }
  public handleAuthorization = async (serviceName: string) => {
    const serviceToAuthorize = AVAILABLE_SERVICES.find(service => service.serviceNameId === serviceName);
    if (serviceToAuthorize) {
      window.location.assign(serviceToAuthorize.serviceAuthUrl);
      return;
    }

    throw Error(`Unknown service service: ${serviceName}`);
  };

  public handleOauthLogin = async () => {
    const code = getCodeFromUrl();
    const redirect_uri = window.location.origin + window.location.pathname;
    if (code === null) {
      console.error(new Error("Code query missing"));
      return;
    }

    const ROUTE_SERVICE_MAP = {
      [AuthRoutes.AUTH_SPOTIFY]: ThirdPartyServices.SPOTIFY,
      [AuthRoutes.AUTH_HUSQVARNA]: ThirdPartyServices.HUSQVARNA,
      [AuthRoutes.AUTH_MIELE]: ThirdPartyServices.MIELE,
      [AuthRoutes.AUTH_HOME_CONNECT]: ThirdPartyServices.HOMECONNECT,
    } as {[key: string]: ThirdPartyServices;};

    if (!ROUTE_SERVICE_MAP[window.location.pathname]) {
      throw new Error("Invalid auth request");
    }

    return await clientModules.getClientApi().standardAuth({ code, redirect_uri, serviceName: ROUTE_SERVICE_MAP[window.location.pathname] });

  };
}
