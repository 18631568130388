import React from "react";
import "./Input.scss";
interface IInputProps {
  name: string;
  type?: string;
  label?: string;
  autocomplete?: string;
}

export function Input(props: IInputProps) {
  return (
    <label className="input-label">
      {props.label}
      <input
        autoComplete={props.autocomplete}
        className="input"
        type={props.type}
        name={props.name} />
    </label>
  );
}
