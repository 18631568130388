export function parseQuery(queryString: string) {
  const query: {[x: string]: any;} = {};
  const pairs = (queryString.startsWith("?") ? queryString.substr(1) : queryString).split("&");

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");

    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }

  return query;
}

export function getCodeFromUrl(): string | null {
  const query = parseQuery(window.location.search);
  if (query && query.code) {
    return query.code;
  }

  return null;
}
