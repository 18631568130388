import React, { useContext, useEffect, useState } from "react";
import { TopBarView } from "./TopBarView";
import { useHistory } from "react-router-dom";
import { MainRoutes } from "../../router/Routes";
import { User } from "firebase/auth";
import { clientModules } from "../../modules/ClientModules";
import { AppContext } from "../../context/AppContext";

interface ITopBarControllerProps {
}

export function TopBarController(props: ITopBarControllerProps) {
  const appContext = useContext(AppContext);
  const history = useHistory();

  const handleLogout = () => {
    history.push(MainRoutes.LOGOUT);
  };

  const handleOpenSettings = () => {
    history.push(MainRoutes.SETTINGS);
  };

  return (
    <TopBarView
      user={appContext.authorizedUser}
      onLogout={handleLogout}
      onOpenSettings={handleOpenSettings}/>
  );
}
