import { createContext } from "react";
import { LanguageKey, Translation } from "../translations/Translations";

interface ILanguageContext {
  translate: (key: Translation) => string;
  setLanguage: (languageKey: LanguageKey) => void;
  language: LanguageKey;
  supportedLanguages: string[];
}

export const LanguageContext = createContext<ILanguageContext>({
  language: LanguageKey.EN,
  supportedLanguages: ["sk", "hu", "en"],
  setLanguage: () => {},
  translate: () => "Translate method not defined",
});

