import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ILoginData } from "../../interfaces/ILogin";
import { MainRoutes } from "../../router/Routes";
import "./LoginView.scss";
import { Translation } from "../../translations/Translations";
import { LanguageContext } from "../../context/LanguageContext";
import { ActionButton } from "../../components/ActionButton/ActionButton";
import { Input } from "../../components/Input/Input";
import { LanguagePicker } from "../../components/LanguagePicker/LanguagePicker";

interface ILoginViewProps {
  isForgotPassword: boolean;
  onLogin: (loginData: ILoginData) => void;
  onForgotPassword: (loginData: ILoginData) => void;
}

export function LoginView(props: ILoginViewProps) {
  const languageContext = useContext(LanguageContext);

  const handleLoginSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let result: ILoginData = {
      password: "",
      username: "",
    };

    for (const [name, value] of data) {
      result = { ...result, [name]: value };
    }

    props.onLogin(result);
  };

  const handleRecoverPasswordSubmit = (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let result: ILoginData = {
      password: "",
      username: "",
    };

    for (const [name, value] of data) {
      result = { ...result, [name]: value };
    }

    props.onForgotPassword(result);
  };

  const loginForm = () => (
    <form
      className="form-container"
      onSubmit={handleLoginSubmit}>
      <div className="form-container-language-picker">
        <LanguagePicker/>
      </div>
      <img
        className="login-logo"
        src={`${process.env.PUBLIC_URL}/assets/logo.png`}
      />
      <div className="login-view-input">
        <Input
          label={languageContext.translate(Translation.USERNAME)}
          type="text"
          name="username"
          autocomplete="email"
        />
      </div>
      <div className="login-view-input">
        <Input
          label={languageContext.translate(Translation.PASSWORD)}
          type="password"
          name="password"
          autocomplete="password"
        />
      </div>
      <Link
        className="link"
        to={{
          pathname: MainRoutes.FORGOT_PASSWORD,
        }}
      >
        {languageContext.translate(Translation.FORGOT_PASSWORD)}
      </Link>
      <div className="login-view-submit-button">

        <ActionButton type="submit">
Submit
        </ActionButton>
      </div>
    </form>
  );

  const forgotPasswordForm = () => (
    <form
      className="form-container"
      onSubmit={handleRecoverPasswordSubmit}>
      <img
        className="login-logo"
        src={`${process.env.PUBLIC_URL}/assets/logo.png`}
      />
      <h5>
Enter your email to recover your password
      </h5>
      <div className="login-view-input">
        <Input
          label={languageContext.translate(Translation.USERNAME)}
          type="text"
          name="username"
          autocomplete="email"
        />
      </div>
      <Link
        className="link"
        to={{
          pathname: MainRoutes.LOGIN,
        }}
      >
        Back to login
      </Link>
      <br />
      <br />
      <ActionButton type="submit">
Submit
      </ActionButton>
    </form>
  );

  return (
    <div className="flex-container">
      {props.isForgotPassword ? forgotPasswordForm() : loginForm()}
    </div>
  );
}
