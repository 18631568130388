import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, connectAuthEmulator, getAuth } from "firebase/auth";
import { AppConfig } from "../Appconfig";
import { connectFunctionsEmulator, Functions, getFunctions } from "firebase/functions";
import { ClientApi } from "./ClientApi";
import { doc, getFirestore, Firestore, connectFirestoreEmulator, deleteDoc, getDoc } from "firebase/firestore";

class ClientModules {
  _app: FirebaseApp;
  _functions: Functions;
  _auth: Auth;
  _clientApi: ClientApi;
  _db: Firestore;
  constructor() {
    this._app = initializeApp(AppConfig.firebaseConfig);
    this._functions = getFunctions(this._app, "europe-west1");
    this._auth = getAuth(this._app);
    this._clientApi = new ClientApi(this._functions);
    this._db = getFirestore(this._app);
    if (process.env.REACT_APP_ENABLE_EMULATORS === "true") {
      console.warn("Emulators enabled!");
      connectAuthEmulator(this._auth, "http://localhost:9099");
      connectFunctionsEmulator(this._functions, "localhost", 5001);
      connectFirestoreEmulator(this._db,"localhost", 9002);
    }
  }

  getAuth() {
    return this._auth;
  }

  getFunctions() {
    return this._functions;
  }

  getClientApi() {
    return this._clientApi;
  }

  getServices = async (): Promise<string[]> => {
    const currentUser = this._auth.currentUser;
    if (currentUser) {
      const serviceRef = doc(this._db, "users", currentUser.uid);
      const document = await getDoc(serviceRef);
      const documentData = document.data();
      if (documentData && documentData.services) {
        return documentData.services;
      }
    }

    return [];
  };

  getApikey = async (keyName: string): Promise<string|null> => {
    const currentUser = this._auth.currentUser;
    if (currentUser) {
      const serviceRef = doc(this._db, "users", currentUser.uid);
      const document = await getDoc(serviceRef);
      const documentData = document.data();
      if (documentData && documentData[keyName]) {
        return documentData[keyName];
      }
    }

    return null;
  };
}

const clientModules = new ClientModules();

export { clientModules };
