import { FirebaseError } from "firebase/app";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { clientModules } from "../../modules/ClientModules";
import { ILoginData } from "../../interfaces/ILogin";
import { MainRoutes } from "../../router/Routes";
import { errorToast, successToast } from "../../utils/toast";
import { LoginView } from "./LoginView";

export function LoginController() {
  const auth = clientModules.getAuth();
  const history = useHistory();

  const handleLogin = async (loginData: ILoginData) => {
    try {
      await signInWithEmailAndPassword(
        auth,
        loginData.username,
        loginData.password,
      );
      history.push(MainRoutes.SERVICES);
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === "auth/user-not-found") {
          errorToast("Invalid username");
          return;
        } else if (error.code === "auth/wrong-password") {
          errorToast("Invalid password");
          return;
        }
      }

      errorToast("Unknown error pleas contact support");
      console.error(error);
    }
  };

  const handleforgotPassword = async (data: ILoginData) => {
    const locale = localStorage.getItem("locale");

    auth.useDeviceLanguage();
    auth.languageCode = locale;
    sendPasswordResetEmail(auth, data.username)
      .then(() => {
        successToast("A password reset email has been sent to your email.");
        history.push(MainRoutes.LOGIN);
      })
      .catch((error) => {
        if (error instanceof Error) {
          errorToast(`Unable to send password reset email: ${error.message}`);
        }

        console.error(error);
      });
  };

  return <LoginView
    isForgotPassword={window.location.pathname === MainRoutes.FORGOT_PASSWORD}
    onLogin={handleLogin}
    onForgotPassword={handleforgotPassword}
  />;
}
