import Modal from "react-modal";
import "./Services.scss";
import { ServiceCard } from "../../components/ServiceCard/ServiceCard";
import { IService } from "../../interfaces/IServices";
import { User } from "firebase/auth";
import { useContext, useState } from "react";
import { Translation } from "../../translations/Translations";
import { LanguageContext } from "../../context/LanguageContext";

interface IServicesProps {
  activeServices: IService[];
  inactiveServices: IService[];
  servicesLoadingState: string[];
  user?: User;
  onServiceAuthorization: (service: string) => void;
  onRemoveService: (serviceId: string) => void;
  onOpenSettings: () => void;
}

export function ServicesView(props: IServicesProps) {
  const languageContext = useContext(LanguageContext);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className="services-container">
        <h1>
          {languageContext.translate(Translation.SERVICES_VIEW_TITLE)}
        </h1>
        <div className="services-grid">
          {props.activeServices.map(service => (
            <ServiceCard
              isLoading={props.servicesLoadingState.includes(service.serviceNameId)}
              key={service.serviceNameId}
              img={`${process.env.PUBLIC_URL}/assets/service-images/${service.serviceNameId}.svg`}
              title={service.serviceNiceName}
              onDeleteClick={() => {
                props.onRemoveService(service.serviceNameId);
              }}
            />
          ))}
          <ServiceCard
            img={
              <img
                className="service-card-image-add"
                src={`${process.env.PUBLIC_URL}/assets/plus-circle.svg`}
              />
            }
            title={languageContext.translate(Translation.ADD_SERVICE)}
            onCardClick={(event) => {
              event.preventDefault();
              setIsAddModalOpen(true);
            }}
          />
        </div>
      </div>
      <Modal
        isOpen={isAddModalOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setIsAddModalOpen(false);
        }}
        // style={customStyles}
        className="modal"
        overlayClassName="modal-overlay"
        contentLabel="Add services"
      >
        <h2 className="services-modal-title-container">
          {languageContext.translate(Translation.ADD_SERVICE)}
        </h2>

        <div className="services-grid">
          {props.inactiveServices.map(service => (
            <ServiceCard
              key={service.serviceNameId}
              img={`${process.env.PUBLIC_URL}/assets/service-images/${service.serviceNameId}.svg`}
              title={service.serviceNiceName}
              onCardClick={(event) => {
                event.preventDefault();
                props.onServiceAuthorization(service.serviceNameId);
              }}
            />
          ))}
        </div>
      </Modal>
    </>
  );
}
