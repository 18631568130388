import { User } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AVAILABLE_SERVICES,
} from "../../Appconfig";
import { clientModules } from "../../modules/ClientModules";
import { IService } from "../../interfaces/IServices";
import { OauthLoginHandler } from "../../logic/OauthLoginHandler";
import { errorToast } from "../../utils/toast";
import { ServicesView } from "./ServicesView";
import { TopBarController } from "../topBar/TopBarController";
import { AppContext } from "../../context/AppContext";

export function ServicesController() {
  const appContext = useContext(AppContext);
  const [activeServices, setActiveServices] = useState<IService[]>([]);
  const [inactiveServices, setInactiveServices] = useState<IService[]>([]);
  const [servicesLoadingState, setServicesLoadingState] = useState<string[]>([]);

  const handleServiceAuthRequest = (serviceName: string) => {
    const oauthLoginHandler = new OauthLoginHandler();

    oauthLoginHandler.handleAuthorization(serviceName).finally(() => {

    });
  };

  const handleRemoveService = async (serviceId: string) => {
    try {
      setServicesLoadingState([...servicesLoadingState, serviceId]);
      await clientModules._clientApi.removeService({ serviceId });
      await loadData();
      setServicesLoadingState(servicesLoadingState.filter(id => id !== serviceId));
    } catch (error) {
      errorToast("Unable to delete record");
    }
  };

  const loadData = async () => {
    try {
      const enabledServices = await clientModules.getServices();
      const services = AVAILABLE_SERVICES.filter(value =>
        enabledServices.includes(value.serviceNameId),
      );
      const inactiveServices = AVAILABLE_SERVICES.filter(
        value => !enabledServices.includes(value.serviceNameId),
      );

      setActiveServices(services);
      setInactiveServices(inactiveServices);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (appContext.authorizedUser) {
      loadData().finally(() => {});
    }
  },[]);

  return (
    <>
      <TopBarController />
      <ServicesView
        user={appContext.authorizedUser || undefined}
        servicesLoadingState={servicesLoadingState}
        activeServices={activeServices}
        inactiveServices={inactiveServices}
        onServiceAuthorization={handleServiceAuthRequest}
        onRemoveService={handleRemoveService}
        onOpenSettings={() => {}}
      />
    </>
  );
}
